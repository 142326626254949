

















































import { AccountsService, User } from '@/api/braendz';
import { PermissionLevel } from '@/models/PermissionLevel';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import NextSectionButton from '@/components/Buttons/NextSectionButton.vue';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';

@Component({
  components: {
    NextSectionButton
  },
  metaInfo() {
    return {
      title: this.$i18n.t("notFound.title").toString(),
    };
  }
})
export default class Home extends Vue {

  public get user(): User | null {
    return this.$store.state.user.object as User | null;
  }

  public get hasPermissionUpdateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Update);
  }
  public get hasPermissionCreateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Create);
  }
  public get hasPermissionDeleteUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Delete);
  }
  public get hasPermissionReadUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Read);
  }

  public get hasFeatureDE(): boolean {
    return this.$store.getters.hasFeature('CanSearchDE');
  }

  public get hasFeatureDisplayOwner(): boolean {
    return this.$store.getters.hasFeature('CanDisplayOwner');
  }

}
