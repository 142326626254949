var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"dark":"","rounded":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"content":_vm.newNotifications.length,"value":_vm.newNotifications.length,"color":"tertiary","overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-light fa-bell")])],1)],1)]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-toolbar',{attrs:{"flat":"","height":"25"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","x-small":"","disabled":_vm.notifications.length === 0 && _vm.newNotifications.length === 0},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("fa-light fa-trash-can")])],1)],1),_c('v-list',{staticClass:"pa-0"},[(_vm.newNotifications.length > 0)?_vm._l((_vm.sortedNewNotifications),function(item){return _c('v-list-item',{key:item.key,staticClass:"new-background"},[_c('v-list-item-avatar',[(item.severity === 'INFO')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"tertiary"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-info")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.info')))])]):_vm._e(),(item.severity === 'WARNING')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"orange lighten-1"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-triangle-exclamation")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.warning')))])]):_vm._e(),(item.severity === 'ERROR')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red darken-3"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-xmark")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.error')))])]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$d(item.timestamp, 'long'))+" ")])],1)],1)}):_vm._e(),(_vm.notifications.length > 0)?_vm._l((_vm.sortedNotifications),function(item){return _c('v-list-item',{key:item.key},[_c('v-list-item-avatar',[(item.severity === 'INFO')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"tertiary"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-info")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.info')))])]):_vm._e(),(item.severity === 'WARNING')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"orange lighten-1"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-triangle-exclamation")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.warning')))])]):_vm._e(),(item.severity === 'ERROR')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red darken-3"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-xmark")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.error')))])]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$d(item.timestamp, 'long'))+" ")])],1)],1)}):_vm._e(),(_vm.notifications.length === 0 && _vm.newNotifications.length === 0)?[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"tertiary"}},'v-icon',attrs,false),on),[_vm._v("fa-light fa-circle-info")])]}}],null,false,4177789682)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar.notifications.tooltips.info')))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('toolbar.notifications.noNotifications'))+" ")])],1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }