/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandQueryExecutionOptions } from '../models/BrandQueryExecutionOptions';
import type { BrandQueryResult } from '../models/BrandQueryResult';
import type { BrandQuerySession } from '../models/BrandQuerySession';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BrandQueriesService {

    /**
     * Gets the query result of a brand query history item.
     * @param id The brand query history id.
     * @returns BrandQueryResult Success
     * @throws ApiError
     */
    public static getBrandQueryHistoryItemResult(
id: string,
): CancelablePromise<BrandQueryResult> {
        return __request({
            method: 'GET',
            path: `/api/v1/BrandQueryHistory/${id}/result`,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Executes a brand query and returns the brand lookup results.
     * @param requestBody The query parameters.
     * @returns BrandQueryResult Success
     * @throws ApiError
     */
    public static executeBrandQuery(
requestBody?: BrandQuerySession,
): CancelablePromise<BrandQueryResult> {
        return __request({
            method: 'POST',
            path: `/api/v1/BrandQueries`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * Executes a stored brand query and returns the brand lookup results.
     * @param id The brand query id.
     * @param requestBody Execution options.
     * @returns BrandQueryResult Success
     * @throws ApiError
     */
    public static executeStoredBrandQuery(
id: number,
requestBody?: BrandQueryExecutionOptions,
): CancelablePromise<BrandQueryResult> {
        return __request({
            method: 'POST',
            path: `/api/v1/StoredBrandQueries/${id}/BrandQueries`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Executes a brand query by an uploaded image and returns the brand lookup results.
     * @param formData 
     * @returns BrandQueryResult Success
     * @throws ApiError
     */
    public static executeFigurativeBrandQuery(
formData?: {
imageFile?: Blob;
},
): CancelablePromise<BrandQueryResult> {
        return __request({
            method: 'POST',
            path: `/api/v1/Figurative/BrandQueries`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Client Error`,
            },
        });
    }

}