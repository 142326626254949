



























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class NextSectionButton extends Vue {
    // Members:

    // Properties:

    // Getters:

    // Component Lifecycle Methods:

    // Methods:

    // Events:
}
