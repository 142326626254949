






















































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Notification, NotificationSeverity } from "../models/Notification";
import { GlobalEventType } from "@/models/GlobalEventType";
import { DescribedError } from "@/models/Errors";
import { ApiError } from "@/api/braendz";

@Component({})
export default class NotificationsToolbarItem extends Vue {
  // Properties:
  public notifications: Notification[] = [];
  public newNotifications: Notification[] = [];

  public isOpened = false;

  public get sortedNotifications(): Notification[] {
    return this.notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  public get sortedNewNotifications(): Notification[] {
    return this.newNotifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  public created(): void {
    // eslint-disable-next-line
    this.$emitter.on(GlobalEventType.ErrorOccurred, (error: any) => {
      if ((error as DescribedError).friendlyMessage) {
        this.addNewNotification(new Notification(NotificationSeverity.Error, error.friendlyMessage));
      } else if ((error as ApiError).message) {
        this.addNewNotification(new Notification(NotificationSeverity.Error, error.message));
      } else if ((error as Error).message) {
        this.addNewNotification(new Notification(NotificationSeverity.Error, error.message));
      } else {
        this.addNewNotification(new Notification(NotificationSeverity.Error, error.toString()));
      }
    });

    this.$emitter.on(GlobalEventType.Notified, (notification: Notification) => {
      this.addNewNotification(notification);
    });
  }

  public addNewNotification(notification: Notification): void {
    this.newNotifications.push(notification);
  }

  @Watch('isOpened')
  private onIsOpenedChanged(isOpened: boolean): void {
      if(!isOpened) {
        for(const notification of this.newNotifications) {
          this.notifications.push(notification);
        }
        this.newNotifications = [];
      }
  }

  // Methods
  public clear(): void {
    this.notifications = [];
    this.newNotifications = [];
  }
}
