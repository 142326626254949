














































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import AppBar from '@/components/AppBar.vue';
import NotificationBar from '@/components/NotificationBar.vue';
import CookieBanner from './components/Cookies/CookieBanner.vue';
import Footer from './components/Footer.vue';

@Component({
  components: {
    AppBar,
    NotificationBar,
    CookieBanner,
    Footer
  }
})
export default class App extends Vue {
}

