/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DomainAvailability } from '../models/DomainAvailability';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DomainsService {

    /**
     * Gets a list of supported top level domains.
     * @returns string Success
     * @throws ApiError
     */
    public static getTopLevelDomains(): CancelablePromise<Array<string>> {
        return __request({
            method: 'GET',
            path: `/api/v1/tlds`,
        });
    }

    /**
     * Checks the availability of a domain.
     * @param domain The domain
     * @returns DomainAvailability Success
     * @throws ApiError
     */
    public static checkDomainAvailability(
domain: string,
): CancelablePromise<DomainAvailability> {
        return __request({
            method: 'GET',
            path: `/api/v1/Domains/${domain}`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Checks the availability of multiple domains.
     * @param requestBody The domains
     * @returns DomainAvailability Success
     * @throws ApiError
     */
    public static checkDomainAvailabilities(
requestBody?: Array<string>,
): CancelablePromise<Array<DomainAvailability>> {
        return __request({
            method: 'PUT',
            path: `/api/v1/Domains`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Client Error`,
            },
        });
    }

}