



































































































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { Brand, GoodOrService } from '@/api/braendz';

import BrandDetailsAddressField from './BrandDetailsAddressField.vue';
import BrandDetailsProcessTimeline from './BrandDetailsProcessTimeline.vue';
import TruncateTooltip from '../TruncateTooltip.vue';
import BrandDetailsTextField from './BrandDetailsTextField.vue';

@Component({
    components: {
        BrandDetailsTextField,
        BrandDetailsAddressField,
        BrandDetailsProcessTimeline,
        TruncateTooltip
    }
})
export default class BrandDetails extends Vue {
    // Member
    public defaultCellWidth = 250;


    // Properties
    @Prop({ required: true })
    public brand!: BusyObject<Brand>;

    // Getter
    public get classifiedGoodsAndServices(): GoodOrService[] | undefined {
        return this.brand.object?.goodsServices?.filter(i => i.niceClass?.key !== undefined)?.sort((a, b) => (a.niceClass?.key ?? '0').localeCompare(b.niceClass?.key ?? '0', undefined, { numeric: true, sensitivity: 'base' }));
    }

    public get unclassifiedGoodsAndServices(): GoodOrService[] | undefined {
        return this.brand.object?.goodsServices?.filter(i => i.niceClass?.key === undefined);
    }

    public get originBrandTypeTitle(): string {
        if(this.brand.object) {
            return this.$util.concat(' - ', this.brand.object.registrationOfficeCode?.shortDescription ?? this.brand.object.registrationOfficeCode?.key, this.$t('brand.details.originBrandType').toString());
        }
        else {
            return this.$t('brand.details.originBrandType').toString();
        }
    }

    // Lifecycle Methods:

    // Methods:
    public toNullableDate(date: string | null | undefined): Date | null {
        return date ? new Date(date) : null;
    }

}
