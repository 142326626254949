import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-pro/css/all.css'
import light from '@/themes/light';

// Custom Icons:
import CombinedMark from '@/icons/CombinedMark.vue';
import NoText from '@/icons/NoText.vue'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: light
    },
    dark: false
  },
  icons: {
    values: {
      'combined-mark': {
        component: CombinedMark,
      },
      'no-text': {
        component: NoText,
      }
    }
  }
});

export default vuetify;