






























































































































































































































































































import { AccountsService, User } from '@/api/braendz';
import { PermissionLevel } from '@/models/PermissionLevel';
import { PermissionResourceType } from '@/models/PermissionResourceType';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import NextSectionButton from '@/components/Buttons/NextSectionButton.vue';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';

import BrandsByOfficeBarChart from '@/components/Statistics/BrandsByOfficeBarChart.vue';
import BrandsByStateCategoryDonutChart from '@/components/Statistics/BrandsByStateCategoryDonutChart.vue';
import BrandsByBrandTypeDonutChart from '@/components/Statistics/BrandsByBrandTypeDonutChart.vue';
import BrandThumbnailsCarousel from '@/components/Statistics/BrandThumbnailsCarousel.vue';
import TotalBrandsBox from '@/components/Statistics/TotalBrandsBox.vue';
import TwoColumns from '@/components/Layout/TwoColumns.vue';
import { BusyObject } from '@/models/Busy';

@Component({
  components: {
    NextSectionButton,
    BrandsByOfficeBarChart,
    BrandsByStateCategoryDonutChart,
    BrandThumbnailsCarousel,
    TotalBrandsBox,
    BrandsByBrandTypeDonutChart,
    TwoColumns
  },
  metaInfo() {
    return {
      title: this.$i18n.t("home.title").toString(),
      meta: [
        // Search Engines:
        { name: 'title', content: this.$i18n.t("home.metaTags.title").toString() },
        { name: 'description', content: this.$i18n.t("home.metaTags.description").toString() },
        { name: 'keywords', content: this.$i18n.t("metaTags.keywords").toString() },
        { name: 'author', content: this.$i18n.t("metaTags.author").toString() },
        { name: 'language', content: this.$i18n.locale },
        { name: 'robots', content: [RobotOption.All].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("home.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("home.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Home extends Vue {
  // Member:
  public globalBrandStatistic = new BusyObject<any>();

  // Getter
  public get user(): User | null {
    return this.$store.state.user.object as User | null;
  }

  public get hasPermissionUpdateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Update);
  }
  public get hasPermissionCreateUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Create);
  }
  public get hasPermissionDeleteUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Delete);
  }
  public get hasPermissionReadUsers(): boolean {
    return this.$store.getters.hasPermission(PermissionResourceType.Users, PermissionLevel.Read);
  }
  public get hasFeatureDE(): boolean {
    return this.$store.getters.hasFeature('CanSearchDE');
  }
  public get hasFeatureDisplayOwner(): boolean {
    return this.$store.getters.hasFeature('CanDisplayOwner');
  }

  // Lifecycle Methods:
  public mounted() {
    this.$store.dispatch("updateMasterData");
    this.loadGlobalBrandStatistic();
  }

  // Methods
  public async loadGlobalBrandStatistic() {
      await this.globalBrandStatistic.load(async () => {
        return await this.$util.getData('https://publiczoneweu.blob.core.windows.net/statistics/global/all.json');
      });
  }

}
